import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';


function App() {
  return (
    <React.Fragment>
        <Router>
          {/* <ScrollToTop /> */}
          {/* <SideBar /> */}
          <Switch>
            <Route exact path="/:id" component={Home} />
            <Redirect from="/index.html" to="/" />
            <Route exact path="/" component={Dashboard} />            
            {/* <Route render={() => (<Redirect to="/"  />)} /> */}
          </Switch>
          {/* <Footer /> */}
        </Router>
      </React.Fragment>
  );
}

export default App;
