import React, { useState, useEffect  } from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';
import { MDBDataTable  } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import { Form, Button, Modal, Spinner, Card, Jumbotron} from 'react-bootstrap';
import axios from 'axios';
import CsvDownload from 'react-json-to-csv';
var randomstring = require("randomstring");

const TITLE = "Home - swmc.me"
// const loginEmail = 'admin'
// const loginPassword = "xld!@#$"

// const loginDetails = [{
//     user : 'admin',
//     password: 'xld!@#$'
// },
// {
//     user: 'anon',
//     password: 'anon!@#$'
// },
// {
//     user: 'andrew',
//     password: 'andrew!@#$'
// },
// {
//     user: 'brenda',
//     password: 'brenda!@#$'
// }];

function serialize(form) {
    let inputs = form.elements;
    let testArray = {};
    for(let i=0; i < inputs.length; i++) {
        testArray[(inputs[i].id)] = inputs[i].value;
    }
    return (testArray);
}

function Dashboard() {
    const [getData, setgetData] = useState([]);
    const [tinyGenURL, setTinyGenURL] = useState(null)
    const [showTY, setTYModal] = useState(false)
    const [error, setError] = useState(false)
    const [visiblePanel, setVisiblePanel] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [spinnerDisplay, setSpinnerDisplay] = useState(true)
    const [loginDetails, setLoginDetails] = useState([])

    useEffect(() => {
        updateData()
        getUsers()
        // if(getData.length == 0){
        //     // setSpinnerDisplay(true)
        //     setTimeout(() => {
        //         setSpinnerDisplay(false)
        //     }, 30000);
        // }
        // loginDetails.filter((item) => {
        //     if(localStorage.getItem('email') == item.user && localStorage.getItem('password') == item.password){
        //         setVisiblePanel(true)
        //     }
        // })
        // setError(true)
    },[]);

    function getUsers() {
        axios.get('https://swmc-landingpages.s3.amazonaws.com/swmc-me/users.json')
            .then(response => {
                console.log(response.data)
                setLoginDetails(response.data)
                response.data.filter((item) => {
                    if(localStorage.getItem('email') == item.user && localStorage.getItem('password') == item.password){
                        setVisiblePanel(true)
                    }
                })
            });
    }

    function updateData(){
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        const blogGet = {
            "method": "get",
            headers: myHeaders,
        }
        axios.post('https://gxe6hppp0i.execute-api.us-west-1.amazonaws.com/prod/swmc-tiny-url-fun-api', blogGet)
            .then((response) => {
                console.log(response.data);
                fetch(response.data.url, blogGet)
                .then( response => response.json())
                .then(
                    // handle the result
                    (result) => {
                        if(result.length == 0){
                            setSpinnerDisplay(false)
                        }
                        setgetData(result)
                    }
                )
            }, (error) => {
                console.log(error);
            });
    }
    function createURL(){
        const data = document.querySelector('#blogForm');
        const getData = serialize(data);
        var blogNameURL = new URL(getData.fullUrl)
        var customChar = blogNameURL.searchParams.get("tt")
        const randomurl = randomstring.generate({
            length: 10,
            charset: getData.blogName,
            capitalization: "lowercase"
          });
        const tinyStr = randomurl.replace(/[^a-zA-Z ]/g, "m")
        const tinyURL = 'https://swmc.me/'+tinyStr
        // const tinyURL = 'https://staging.xldynamics.com/'+randomurl
        // const tinyURL = 'http://localhost:3000/'+randomurl
        const tinyURLGenerated = tinyURL.split(" ").join("")  
        
        setTinyGenURL("loading")        
        setTimeout(()=>{
            setTinyGenURL(tinyURLGenerated)
        }, 100)
    }
    const handleClose = () => setTYModal(false);

    const handleSubmit = event => {
        event.preventDefault();
        const data = document.querySelector('#blogForm');
        let blogForm = document.getElementById("blogForm");
        const getData = serialize(data);
       
        const BlogData = {
            "method": "post",
            "BlogName": getData.blogName,
            "FullURL": getData.fullUrl,
            "TinyURL": tinyGenURL
        }

        axios.post('https://gxe6hppp0i.execute-api.us-west-1.amazonaws.com/prod/swmc-tiny-url-fun-api', BlogData)
        .then(res => {
            console.log(res)
            blogForm.reset()
            setTinyGenURL(null)
            setTYModal(true);
            updateData()     
        })
        .catch(function(error) {                 
            console.log("swmc-tiny-url-API error status " +  JSON.stringify(error));
        })
            
    }

    const handleEmailChange = event => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = event => {
        setPassword(event.target.value);
    }

    const signIn = event =>{
        event.preventDefault();
        loginDetails.filter((item) => {
            if(item.user == email && item.password == password) {
                localStorage.setItem('email', email)
                localStorage.setItem('password', password)
                setVisiblePanel(true)
                return;
            } else {
                setError(true)
            }
        })


        // localStorage.setItem('email', loginEmail)
        // localStorage.setItem('password', loginPassword)
        // if(loginEmail == email && loginPassword == password){
        //     setVisiblePanel(true)
        // } else {
        //     localStorage.removeItem('email');
        //     localStorage.removeItem('password');
        //     setError(true)
        // } 
    }

    const signOut = event => {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        setVisiblePanel(false)
        setError(false)
    }

    const columns = [
        {
            label: 'Blog Name',
            field: 'BlogName',
            sort: 'desc',
            width: 100
          },
          {
            label: 'Full URL',
            field: 'FullURL',
            // sort: 'desc',
            width: 400
          },
          {
            label: 'Tiny URL',
            field: 'TinyURL',
            // sort: 'desc',
            width: 200
          }
    ]
    const data = {columns: columns ,
        rows: getData}
    return(
        <React.Fragment>
             <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <div className="table-responsive text-nowrap p-5" >
                <div className="container">
                    <button className={visiblePanel ? "btn btn-md btn-danger sign-out-btn btn-block text-uppercase": ' d-none'} type="button" onClick={signOut}>Sign out</button>
                    <h2 className="text-center pb-2">Generate Tiny URL</h2>                    
                </div>
                    <div className={"row" + (visiblePanel ? ' d-none ' : ' d-block')}>                        
                        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        {
                            error ? 
                            <div className="alert alert-danger" role="alert">
                                Please login with valid username and password 
                            </div> : ''
                        }
                        
                            <div className="card-signin card my-5">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Sign In</h5>
                                    <form className="form-signin" onSubmit={signIn}>
                                    <div className="form-label-group">
                                        <input type="text" id="email" className="form-control" value={email} onChange={handleEmailChange} required autofocus />
                                        <label for="email">User Name</label>
                                    </div>

                                    <div className="form-label-group">
                                        <input type="password" id="password" className="form-control" value={password} onChange={handlePasswordChange} required />
                                        <label for="password">Password</label>
                                    </div>                                
                                    <button className="btn btn-lg btn-danger btn-block text-uppercase" type="submit">Sign in</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                <div className={"container " + (visiblePanel ? ' d-block' : ' d-none')}>
                <Card>
                <Card.Body>
                    <Form id="blogForm" method="POST" encType="multipart/form-data" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label for="blogName" className="font-weight-bold">Blog Name</label>
                            <input type="text" className="form-control" id="blogName" placeholder="Blog Name" required />
                            
                        </div>                            
                        <div className="form-group">
                            <label for="fullUrl" className="font-weight-bold">Full URL<span className="text-muted font-weight-normal"> (click outside to generate tiny URL)</span></label>
                            <input type="text" className="form-control" id="fullUrl" placeholder="Blog Full url" onBlur={createURL} required/>                            
                        </div>
                        {/* {tinyGenURL == null ?
                        <button type="submit" onClick={createURL} className="btn btn-primary">Generate URL</button>
                        : ""
                        } */}
                        {tinyGenURL != null ?
                        <>
                        <div className={"form-group " +(tinyGenURL === null ? ' d-none' : ' d-block')}>
                            <label for="tinyURL" className="font-weight-bold">Tiny URL</label>                            
                            {tinyGenURL == "loading" ?
                            <Spinner animation="border" className="d-block" variant="primary" />:
                            <input type="text" readOnly className="form-control-plaintext" id="tinyURL" value={tinyGenURL}></input>
                            }
                        </div>                                            
                        <button type="submit" className="btn btn-primary">Save</button>
                        </> : ''                   
                        }
                    </Form>
                </Card.Body>
                </Card>
                    <Modal size='md' centered show={showTY} backdrop="static">
                        <Modal.Body>Thank you! Record Saved successfully .</Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className={"mt-5 " + (visiblePanel ? ' d-block' : ' d-none')}>
                    {data.rows.length == 0 ? '' :                    
                    <CsvDownload 
                        data={getData}
                        filename="tiny_URL.csv"
                        style={{ //pass other props, like styles
                            backgroundColor: "#28a745",
                            cursor: "pointer",
                            fontSize:"15px",
                            fontWeight: "bold",
                            margin: "0 10px",
                            display: "block",
                            border: "1px solid #1c7430",
                            color: "#ffffff",
                            padding: "5px",
                            borderRadius: "5px",
                            position: "relative",
                            float: "right",
                            // left:"120px"
                        }}
                        className="text-center"
                    >Export to CSV</CsvDownload>}
                    {data.rows.length == 0 ? 
                        <>
                            {spinnerDisplay ?                         
                                // <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                <div style={{textAlign:"center"}}>                        
                                <Spinner animation="grow" variant="primary" />
                                <Spinner animation="grow" variant="secondary" />
                                <Spinner animation="grow" variant="success" />
                                <Spinner animation="grow" variant="danger" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="info" />
                                <Spinner animation="grow" variant="light" />
                                {/* <Spinner animation="grow" variant="dark" /> */}
                                </div>
                                : 
                                <div className="container text-center"> 
                                    <Jumbotron>
                                        <h1>No records Found!!</h1>
                                        <p>
                                        Create a tiny URL using above <strong>"Generate Tiny URL"</strong> form.
                                        </p>
                                    </Jumbotron>
                                </div>
                            } </>
                        :
                        <MDBDataTable
                            striped
                            bordered 
                            small
                            entries={20}
                            entriesOptions={[10, 20, 40, 80, 100, 200, 300]}
                            data={data}
                            hover
                            searchLabel="Search..."
                        />
                }
                </div>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;