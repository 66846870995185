import React, { useState, useEffect  } from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';
import { MDBDataTable, MDBDataTableV5  } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import { Row, Col, Jumbotron, Container, Form, Button, Modal, Image, Toast, Spinner, Nav, Card } from 'react-bootstrap';
import axios from 'axios';

const TITLE = "Home - swmc.me"

function Home() {
    const [spinnerDisplay, setSpinnerDisplay] = useState(true)
    useEffect(() => {
       var value = updateData()
       if(!value){
        // setSpinnerDisplay(true)
            setTimeout(() => {
                setSpinnerDisplay(false)
            }, 30000);
        }
    //    alert(value)
    },[]);

    function updateData(){
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        const blogGet = {
            "method": "get",
            headers: myHeaders,
        }
        var hasvalue;
        axios.post('https://gxe6hppp0i.execute-api.us-west-1.amazonaws.com/prod/swmc-tiny-url-fun-api', blogGet)
            .then((response) => {
                console.log(response.data);
                fetch(response.data.url, blogGet)
                .then( response => response.json())
                .then(
                    // handle the result
                    (result) => {
                       
                        result.filter( function(data) {
                            if(data.TinyURL == window.location.href){
                                window.location =  data.FullURL
                                hasvalue = true
                            } else {
                                hasvalue = false
                            }
                        })
                        return hasvalue
                    }
                )
            }, (error) => {
                console.log(error);
            });
    }
    return(
        <React.Fragment>
            {
                spinnerDisplay ?
            
            <div style={{textAlign:"center"}}>                        
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="light" />
            </div> : 
            <div className="container"> 
            {/* <Jumbotron> */}
            <div class="error-template">
                <h1>
                    Oops!</h1>
                <h2>
                    404 Not Found</h2>
                <div class="error-details">
                    Sorry, an error has occured, Requested page not found!
                </div>
            </div>
            {/* </Jumbotron> */}
        </div>
            }
        </React.Fragment>
    );
}

export default Home;